.commerces-list {
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
    .mat-list-item-content {
        padding: 0 6px 0 12px !important;
        .mat-mdc-list-item-avatar {
            border-radius: 5% !important;
        }
        .btn-redemm {
            font-size: 20px;
            font-weight: bold;
            display: flex;
            align-items: flex-end;
            padding: 0 0px 0 6px;
            margin-left: 8px;
            .text-percent, .text-cashback {
                text-align: right;
            }
            .text-percent {
                margin-top: 3px;
            }
            .text-cashback {
                font-size: 10px;
                margin-top: -15px;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
            > .mat-button-wrapper {
                flex: 1;
            }
        }
    }
}


.commerce-detail {
    font-size: 0.85rem;
    color: #211F20 !important;
    line-height: 26px;
}