.login-page, .register-page {

    .mat-mdc-form-field {
        width: 100%;
        min-width: 300px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
    mat-card-title {
        margin-bottom: 30px;
    }

    .error {
        padding: 16px;
        color: white;
        background-color: red;
        font-size: 14px;
    }

    .button {
        display: flex;
        justify-content: center !important;
    }

    .login-box, .register-box {
        // background-color: $primary;
        display: -webkit-box;
        display: flex;
        min-height: calc(100vh - 64px); // 64px es la altura de la barra de herramientas
        -webkit-box-pack: center;
        justify-content: center;
        background-color: #ffffff;

        .auth-content {
            width: 100%;
            max-width: 330px;
            padding-left: 15px;
            padding-right: 15px;
            background-color: #fff;
            .btn-register-email {
                text-align: center;
                color: #fff;
                border-color: rgba(0,0,0,0.2);
                background-color: $primary;
                &:hover {
                    color: #fff;
                    background-color: darken($primary, 10);
                }
            }
        }

    }

    .login-logo, .register-logo {
        font-size: 2.1rem;
        font-weight: 300;
        margin-bottom: .9rem;
        text-align: center;
    }

    a {
        text-decoration: none;
        background-color: transparent;
    }
}
