#modal-component-benefits {
    padding: 0;
}

.benefits {
    .carousel-indicators {
        display: none;
    }

    .carousel-inner, .carousel-item {
        height: 100%;
    }
}
