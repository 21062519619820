.carousel-indicators {
    bottom: -36px;
}
.carousel-indicators li {
    border-top: 0;
    border-bottom: 0;
    width: 10px !important;
    height: 10px !important;
    border-radius: 5px;
}
.carousel.tutorial {
    box-shadow: none;
    outline: none;
}
.carousel.tutorial img {
/*    width: 100%;*/
}
#tutorial-footer {
    background: #7f0048;
    overflow: hidden;
}
#tutorial-footer button {
    color: #fff;
    font-size: 0.6rem;
    line-height: 1.8rem;
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
}
#tutorial-footer .tut_btn_skip {
    float: left;
}
#tutorial-footer .tut_btn_next {
    float: right;
}

#modal-component-tutorial {
    padding: 0;
}
.tutorialSlide {
    height: 550px;
    .tit {
        background: #ed038c;
        color: #fff;
        height: 9%;
        text-align: center;
        font-size: 1.3rem;
        font-weight: 600;
    }
    .welcome {
        background: #3badef;
        color: #fff;
        height: 15%;
        text-align: center;
        font-size: 1.3rem;
        font-weight: 700;
    }
    .content {
        background: #3badef;
        color: #fff;
        height: 91%;
        text-align: center;
        font-size: 1.3rem;
        font-weight: 700;

        h3 {
            font-weight: 600;
            font-size: 1.3rem;
            padding: 1rem 0;
        }

        ul {
            font-weight: 600;
            font-size: 1.3rem;
            padding: 0;
            margin: 0 auto;
            width: 70%;
            list-style-type: none;

            li {
                background-image: url(/assets/img/tutorial/check.png);
                background-repeat: no-repeat;
                background-position: 8px 50%;
                background-color: #006186;
                background-size: 16px 12px;
                font-size: 1rem;
                margin-top: 8px;
                line-height: 34px;
                padding-left: 30px;
                text-align: left;
            }
        }

        .tutnote {
            background: #006186;
            border-radius: 10px;
            width: 90%;
            font-weight: 400;
            font-size: 0.75rem;
            padding: 1rem 0;
            margin: 0 auto;
        }
    }
    .step {
        height: 19%;
        font-size: 1.3rem;
        font-weight: 600;
        color: #fff;
        line-height: 1.5rem;

        .col-9 {
            padding-right: 0;
        }
    }
    .stp_1 {
        background: #2d89bd;
    }
    .stp_2 {
        background: #2575a3;
    }
    .stp_3 {
        background: #1f648a;
    }
    .stp_4 {
        background: #175170;
    }
}