// Cambiar font
@use '@angular/material' as mat;

$custom-typography: mat.define-typography-config(
  $font-family: $font-family-sans-serif,
);

@include mat.core();

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
 ),
 typography: $custom-typography,
 density: 0,
));

@include mat.all-component-themes($my-theme);

html {
    --mdc-filled-text-field-focus-active-indicator-color: #00ADEF;
    --mat-select-focused-arrow-color: #00ADEF;
    --mdc-filled-text-field-focus-label-text-color: #00ADEF;
    --mat-option-selected-state-label-text-color: #00ADEF;
}

.mat-mdc-dialog-container {
    --mdc-dialog-container-color: white;
    --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
    --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
    --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.87);
    --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-radio-button.mat-primary {
    --mdc-radio-selected-focus-icon-color: #ED028C;
    --mdc-radio-selected-hover-icon-color: #ED028C;
    --mdc-radio-selected-icon-color: #ED028C;
    --mdc-radio-selected-pressed-icon-color: #ED028C;
    --mat-mdc-radio-checked-ripple-color: #ED028C;
}

.mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-selected-focus-icon-color: #ED028C;
    --mdc-checkbox-selected-hover-icon-color: #ED028C;
    --mdc-checkbox-selected-icon-color: #ED028C;
    --mdc-checkbox-selected-pressed-icon-color: #ED028C;
}

.mat-mdc-raised-button.mat-primary {
    --mdc-protected-button-container-color: #ED028C;
}

.mat-mdc-button.mat-primary, .mat-mdc-icon-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
    color: $blue;
}
.mat-mdc-button {
    --mdc-typography-button-font-weight: 600;
    letter-spacing: normal !important;
}

.mat-drawer-container {
    background-color: inherit;
}

.mat-toolbar.mat-primary {
    background-color: $blue;
}

.mat-toolbar.innerlogo {
    background-image: url(/assets/img/moneo-logo-fill-v1-blanco.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 20px;
}

.mat-mdc-raised-button {
    &.mat-primary, &.mat-yellow {
        border: 1px solid #000000;
        box-shadow: 3px 3px 0 0 rgba(0,0,0,0.80);
        border-radius: 4px;
        font-size: 16px;
        font-weight: bold;
    }

    &.mat-yellow {
        background-color: $yellow !important;
    }

    &.mat-mdc-button-base {
        height: auto;
        line-height: 36px;
    }
    letter-spacing: normal !important;
}

.mat-mdc-progress-spinner circle, .mat-mdc-progress-spinner circle {
    stroke: $blue;
}

.mat-mdc-card-header {
    .mat-mdc-card-title {
        margin-bottom: 8px !important;
    }
}

.mat-mdc-form-field {
    &.mat-form-field-appearance-fill {
        &.no-fill {
            .mdc-text-field--filled:not(.mdc-text-field--disabled) {
                background-color: transparent;
            }
            .mdc-text-field--focused {
                background-color: transparent;
            }
            .mat-mdc-form-field-focus-overlay {
                background-color: transparent;
            }
        }
    }
    &.mat-focused {
        .mat-mdc-form-field-focus-overlay {
            opacity: 0.05;
        }
    }
    .mdc-text-field--filled .mdc-line-ripple::after {
        border-bottom-color: $blue;
    }
    .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
        color: $blue;
    }
    .mdc-floating-label {
        letter-spacing: normal !important;
    }
    letter-spacing: normal !important;
}
.mat-mdc-form-field-error {
    font-size: 10.5px;
    line-height: 10.5px;
    &::before {
        height: 10.5px !important;
    }
}

.mat-mdc-fab.mat-primary {
    background-color: $blue;
}

.mat-mdc-card-avatar {
    &~.mat-mdc-card-header-text {
        .mat-mdc-card-title {
            line-height: inherit !important;
        }
    }
}
.mat-dialog-container {
    padding: 0 !important;
}
.mat-mdc-unelevated-button, .mdc-list-item__primary-text {
    letter-spacing: normal !important;
}