//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

// Material design
//@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

// Fontawesome
@import '@fortawesome/fontawesome-free/css/all.css';

@import 'variables';
@import 'bootstrap';

// Cropperjs
@import 'cropperjs/dist/cropper.css';

@import 'material';
@import 'general';
@import 'login';
@import 'commerces';
@import 'tutorial';
@import 'benefits';

.mdc-list .with-badge-text {
  .mdc-list-item__content {
    overflow: visible;
  }
  .mat-badge-content {
    padding: 0 4px;
    width: auto;
    border-radius: 8px;
  }
}
